import {
  redirectTo,
  RedirectType,
  refreshSyllabus,
  updateCurrentVideoProgress
} from "app/employee/state/syllabus/mutations";
import {IWatchCurrent, VideoPlayer} from "app/employee/video/VideoPlayer";
import {Duration} from "luxon";
import {isNil, isNotNil} from "app/utils/stdlib";
import {useCurrentAssignment, useLatestTerm} from "app/employee/state/syllabus/selectors";
import {noop} from "app/utils/types";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonRouter
} from "@ionic/react";
import {MenuButton} from "app/employee/menu/MenuButton";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {DURATION_ZERO, parseDurationOptional} from "app/utils/dates";
import {FlexColumnCenter} from "app/employee/flexUtils";
import {homeOutline} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import {linkEmplCourseHome} from "app/employee/core/employeeLinks";
import {signatureIcon} from "app/utils/icons/ionicFixedIcons";
import {TermSignatureModal} from "app/employee/syllabus/TermSignatureModal";
import {useRef} from "react";
import {SignatureImage} from "app/employee/syllabus/SignatureImage";

const updateProgress = async (progress: Duration) => {
  await updateCurrentVideoProgress({
    progress: progress.toISO()
  });
};

export const WatchCurrentVideo = () => {
  const {t} = useTranslation();
  const latestTerm = useLatestTerm();
  const inProgress = useCurrentAssignment();
  const ionRouter = useIonRouter();
  const termSignatureModalRef = useRef<HTMLIonModalElement>(null);

  if (isNotNil(inProgress)) {
    const watchedLengthAsDuration = parseDurationOptional(inProgress.lesson.watchedLength);
    const playerControl: IWatchCurrent = {
      type: "current",
      updateProgress: inProgress.lesson.watchCompleted ? noop : updateProgress,
      fromTime: inProgress.lesson.watchCompleted ? DURATION_ZERO : watchedLengthAsDuration,
      watchedLength: watchedLengthAsDuration,
      handleReturn: () => {
        redirectTo(RedirectType.TEST);
        ionRouter.push(linkEmplCourseHome(), "back", "push");
      }
    };
    const videoLesson = inProgress.lesson.videoLesson;
    return (
      <>
        <IonHeader>
          <IonToolbar>
            <HomeBackButton />
            <IonTitle>{videoLesson.title}</IonTitle>
            <MenuButton />
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonRefresher onIonRefresh={refreshSyllabus} slot="fixed">
            <IonRefresherContent />
          </IonRefresher>
          <VideoPlayer playerControl={playerControl} videoLesson={videoLesson} />
        </IonContent>
      </>
    );
  } else {
    const handleOnTermSignClose = async () => {
      termSignatureModalRef.current?.dismiss();
    };

    const handleOnTermSignOpen = () => {
      termSignatureModalRef.current?.present();
    };

    const isCompletedTerm = latestTerm?.__typename === "CompletedTerm";

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <HomeBackButton />
            <IonTitle>{t("syllabus.completed.completed-course")}</IonTitle>
            <MenuButton />
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonRefresher onIonRefresh={refreshSyllabus} slot="fixed">
            <IonRefresherContent />
          </IonRefresher>
          <h1 className="ion-text-center">{t("syllabus.completed.completed-course")}</h1>
          <FlexColumnCenter className="ion-margin-top">
            {isCompletedTerm && (
              <>
                <div className="ion-text-center">{t("syllabus.term-signature.title")}:</div>
                {!isNil(latestTerm.signature) && (
                  <div>
                    <SignatureImage src={latestTerm.signature} />
                  </div>
                )}
                <div>
                  <IonButton onClick={handleOnTermSignOpen}>
                    <IonIcon icon={signatureIcon} slot="start" />
                    {t("syllabus.completed.sign-button", {defaultValue: "Подпис за протокола"})}
                  </IonButton>
                  <TermSignatureModal
                    onSignatureComplete={handleOnTermSignClose}
                    ref={termSignatureModalRef}
                    term={latestTerm}
                  />
                </div>
              </>
            )}
            <div className="ion-margin-top">
              <IonButton color="primary" fill="outline" routerDirection="back" routerLink={linkEmplCourseHome()}>
                <IonIcon icon={homeOutline} slot="start" />
                {t("g.back-to-home")}
              </IonButton>
            </div>
          </FlexColumnCenter>
        </IonContent>
      </>
    );
  }
};
