import {ISyllabusPlanWithTerm, ISyllabusTerm, ISyllabusTermPlan} from "app/employee/state/syllabus/mutations";
import {FlexColumn, FlexColumnCenter} from "app/employee/flexUtils";
import {LessonRow} from "app/employee/syllabus/LessonRow";
import {PlanBadge} from "app/employee/syllabus/PlanBadge";
import {PlanPeriodAndDeadline} from "app/employee/syllabus/PlanPeriodAndDeadline";
import {fractionToPercent, isNil, scoreToPercent} from "app/utils/stdlib";
import {completedVideoLessonPredicate} from "app/employee/syllabus/utils";
import {IonContent, IonIcon, IonPopover, IonProgressBar} from "@ionic/react";
import {Trans, useTranslation} from "react-i18next";
import {DateDisplay} from "app/employee/DateDisplay";
import {IonRowButton} from "app/employee/IonRowButton";
import {TermSignatureModal} from "app/employee/syllabus/TermSignatureModal";
import {useRef} from "react";
import {signatureIcon} from "app/utils/icons/ionicFixedIcons";
import {LinkButton} from "app/employee/LinkButton";
import {SignatureImage} from "app/employee/syllabus/SignatureImage";
import {ExtractType} from "app/utils/types";
import {useId} from "@fluentui/react-hooks";

const VideoLessonProgress = ({termPlan}: {termPlan: ISyllabusTermPlan}) => {
  const {t} = useTranslation();
  const lessons = termPlan.lessons;
  const totalLessonsCount = lessons.length;
  if (termPlan.completed || totalLessonsCount === 0) {
    return null;
  }
  const completedVideoLessons = lessons.filter(completedVideoLessonPredicate);
  const completedLessonsCount = completedVideoLessons.length;
  if (completedLessonsCount === 0) {
    return null;
  }

  const progress = completedLessonsCount / totalLessonsCount;
  const progressPercent = fractionToPercent(progress);
  const averageScorePercent = scoreToPercent(termPlan.averageScore);
  return (
    <>
      <IonProgressBar type="determinate" value={progress} />
      <div>
        {t("syllabus.in-progress.course-progress", {
          completedLessonsCount,
          totalLessonsCount,
          progressPercent,
          averageScorePercent,
          defaultValue:
            "Завършени {{completedLessonsCount}} от {{totalLessonsCount}} урока ({{progressPercent}}) със среден успех {{averageScorePercent}}"
        })}
      </div>
    </>
  );
};

const PlanSummary = ({termPlan}: {termPlan: ISyllabusTermPlan}) => {
  const {t} = useTranslation();
  const plan = termPlan.clientPlan;
  const scorePercent = scoreToPercent(termPlan.averageScore);
  return (
    <>
      <div>
        <PlanBadge plan={plan} /> <PlanPeriodAndDeadline termPlan={termPlan} />
      </div>
      {termPlan.completed && (
        <em>
          <Trans i18nKey="syllabus.completed.completed-at" scorePercent={scorePercent} t={t}>
            Завършено на{" "}
            <strong>
              <DateDisplay dt={termPlan.completedAt} />
            </strong>{" "}
            със среден успех <strong>{{scorePercent}}</strong>
          </Trans>
        </em>
      )}
    </>
  );
};

interface IDetailedProgressPlanProps {
  planWithTerm: ISyllabusPlanWithTerm;
}

const DetailedProgressPlan = ({planWithTerm}: IDetailedProgressPlanProps) => {
  const termPlan = planWithTerm.termPlan;
  const term = planWithTerm.term;

  const isCompletedTerm = term.__typename === "CompletedTerm";

  return (
    <FlexColumn>
      <PlanSummary termPlan={termPlan} />
      {isCompletedTerm && <DetailedProgressSignature completedTerm={term} />}
      <VideoLessonProgress termPlan={termPlan} />
      {termPlan.lessons.map((lesson) => (
        <LessonRow key={lesson.videoLesson.id} lesson={lesson} />
      ))}
    </FlexColumn>
  );
};

const DetailedProgressSignature = ({completedTerm}: {completedTerm: ExtractType<ISyllabusTerm, "CompletedTerm">}) => {
  const {t} = useTranslation();
  const signatureTrigger = useId("DetailedProgressSignature-");
  const termSignatureModalRef = useRef<HTMLIonModalElement>(null);

  const handleOnTermSignClose = () => {
    termSignatureModalRef.current?.dismiss();
  };

  const handleOnTermSignOpen = () => {
    termSignatureModalRef.current?.present();
  };

  if (isNil(completedTerm.signature)) {
    return (
      <div>
        <LinkButton onClick={handleOnTermSignOpen}>
          {t("syllabus.completed.sign-button", {defaultValue: "Подпис за протокола"})}
        </LinkButton>
        <TermSignatureModal
          onSignatureComplete={handleOnTermSignClose}
          ref={termSignatureModalRef}
          term={completedTerm}
        />
      </div>
    );
  } else {
    return (
      <div>
        <LinkButton id={signatureTrigger}>
          {t("syllabus.completed.sign-button", {defaultValue: "Подпис за протокола"})}
        </LinkButton>
        <IonPopover trigger={signatureTrigger}>
          <IonContent className="ion-padding">
            <FlexColumnCenter>
              <SignatureImage src={completedTerm.signature} />
              <IonRowButton onClick={handleOnTermSignOpen}>
                <IonIcon icon={signatureIcon} slot="start" />
                {t("syllabus.completed.sign-button-again", {defaultValue: "Нов подпис"})}
              </IonRowButton>
            </FlexColumnCenter>
          </IonContent>
        </IonPopover>
        <TermSignatureModal
          onSignatureComplete={handleOnTermSignClose}
          ref={termSignatureModalRef}
          term={completedTerm}
        />
      </div>
    );
  }
};

interface IProps {
  plansWithTerm: ISyllabusPlanWithTerm[];
}

export const DetailedProgress = ({plansWithTerm}: IProps) => {
  return (
    <FlexColumn>
      {plansWithTerm.map((planWithTerm) => (
        <DetailedProgressPlan key={planWithTerm.termPlan.clientPlan.id.planId} planWithTerm={planWithTerm} />
      ))}
      {/*{term.adhocVideoLessons.length > 0 && (*/}
      {/*  <Stack>*/}
      {/*    <Stack.Item>*/}
      {/*      <Text variant="mediumPlus">Допълнителни видео уроци</Text>*/}
      {/*    </Stack.Item>*/}
      {/*    <Stack.Item>*/}
      {/*      {term.adhocVideoLessons.map((lesson) => (*/}
      {/*        <LessonDetails key={lesson.videoLesson.id} lesson={lesson} />*/}
      {/*      ))}*/}
      {/*    </Stack.Item>*/}
      {/*  </Stack>*/}
      {/*)}*/}
    </FlexColumn>
  );
};
