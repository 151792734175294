import {hofForwardRef, IIonButton, MaybeNil, noop, RhfProps, RhfRef} from "app/utils/types";
import {FieldPath, FieldValues, useController} from "react-hook-form";
import {ForwardedRef, ReactNode, RefCallback, useCallback, useImperativeHandle, useMemo, useRef} from "react";
import {
  IControlCardFreeNumberInputMethod,
  IControlCardIconSetFragment,
  IControlCardNumberRangeFragment,
  IInitialValueFragment
} from "app/gql/graphqlSchema";
import {IonButton, IonIcon, IonModal, IonText} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {assignRef, DEFAULT_ROUNDING_MODE, isNil, isNotBlank} from "app/utils/stdlib";
import {Color} from "@ionic/core";
import {styled} from "styled-components";
import BigNumber from "bignumber.js";
import {toBigNumber} from "app/utils/validator";
import {
  getInitialValue,
  getInitialValueForIncrement,
  useInputIcons,
  valueToPresentation
} from "app/employee/rhf/number";
import {rangeCheckFactory} from "app/utils/range";
import {NumberTypePad} from "app/employee/rhf/keypad/NumberTypePad";
import useResizeObserver, {ResizeHandler} from "use-resize-observer";

export interface INumberTypePadProps extends Pick<IIonButton, "disabled" | "size"> {
  inputMethod: IControlCardFreeNumberInputMethod;
  unit?: MaybeNil<string>;
  initialValue?: MaybeNil<IInitialValueFragment>;
  iconSet: IControlCardIconSetFragment;
  greenZoneRange?: MaybeNil<IControlCardNumberRangeFragment>;
  boundaryRange?: MaybeNil<IControlCardNumberRangeFragment>;
  onSubmitValue?: MaybeNil<() => void>;
  title?: ReactNode;
  description?: ReactNode;
}

const reportValidity = () => true;

const Wrapper = styled.div`
  display: flex;
`;

export const RhfNumberTypePadInput = hofForwardRef(
  <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    {
      rhf,
      size,
      inputMethod,
      initialValue,
      unit,
      disabled,
      greenZoneRange,
      iconSet,
      onSubmitValue,
      title,
      boundaryRange
    }: INumberTypePadProps & RhfProps<TFieldValues, TName>,
    ref: ForwardedRef<HTMLIonButtonElement>
  ) => {
    const {t} = useTranslation();
    const {
      field: {onChange, ref: fieldRef, value, disabled: rhfDisabled, ...restField},
      fieldState: {error},
      formState: {isSubmitting}
    } = useController(rhf);
    const keypadModal = useRef<HTMLIonModalElement | null>(null);

    const rhfRef = useRef<RhfRef | null>(null);
    const inputRef: RefCallback<HTMLIonButtonElement> = (element) => {
      assignRef(ref, element);

      rhfRef.current = {
        focus: () => element?.focus(),
        select: () => element?.focus(),
        setCustomValidity: noop,
        reportValidity
      };
    };
    useImperativeHandle(fieldRef, () => rhfRef.current);

    const bnValue = useMemo(() => (!isNil(value) ? toBigNumber(value) : null), [value]);

    const {actionText, step} = inputMethod;
    const bnStep = useMemo(() => toBigNumber(step), [step]);
    const checkGreenZoneRange = useMemo(() => rangeCheckFactory(greenZoneRange), [greenZoneRange]);
    const precision = bnStep.decimalPlaces() ?? 0;

    const bnInitialValue = useMemo(() => getInitialValue(initialValue), [initialValue]);

    const onChangeBigNum = useCallback(
      async (value: MaybeNil<BigNumber>) => {
        await keypadModal.current?.dismiss();
        const changeValue = !isNil(value) ? value.toFixed(precision, DEFAULT_ROUNDING_MODE) : null;
        onChange(changeValue);
        onSubmitValue?.();
      },
      [onChange, precision, onSubmitValue]
    );

    const presentationValue = useMemo(() => {
      if (isNil(bnValue)) {
        return isNotBlank(actionText) ? actionText : t("g.select-value");
      } else {
        return valueToPresentation(bnValue, unit, precision);
      }
    }, [actionText, bnValue, precision, t, unit]);

    const color = useMemo((): Color => {
      if (isNil(bnValue)) {
        return "warning";
      } else {
        const greenZoneStatus = checkGreenZoneRange(bnValue);
        if (isNil(greenZoneStatus)) {
          return "primary";
        } else {
          return greenZoneStatus ? "success" : "danger";
        }
      }
    }, [checkGreenZoneRange, bnValue]);

    const decrementHandler = useCallback(
      () =>
        onChangeBigNum(
          !isNil(bnValue) ? bnValue.minus(bnStep) : getInitialValueForIncrement(initialValue, greenZoneRange)
        ),
      [bnStep, bnValue, greenZoneRange, initialValue, onChangeBigNum]
    );
    const incrementHandler = useCallback(
      () =>
        onChangeBigNum(
          !isNil(bnValue) ? bnValue.plus(bnStep) : getInitialValueForIncrement(initialValue, greenZoneRange)
        ),
      [bnStep, bnValue, greenZoneRange, initialValue, onChangeBigNum]
    );

    const {incrementIcon, decrementIcon} = useInputIcons(iconSet);

    /** Due to a bug in Safari, I can't use --height: fit-content */
    const onResize = useCallback<ResizeHandler>((size) => {
      const height = size.height;
      keypadModal.current?.style.setProperty("--height", `${!isNil(height) ? `${height + 8}px` : "90%"}`);
    }, []);
    const {ref: contentRef} = useResizeObserver({onResize});

    return (
      <>
        <Wrapper>
          <IonButton
            color={decrementIcon.color}
            disabled={rhfDisabled || disabled || isSubmitting}
            fill="clear"
            onClick={decrementHandler}
          >
            <IonIcon icon={decrementIcon.icon} slot="icon-only" />
          </IonButton>
          <IonButton
            {...restField}
            color={color}
            disabled={rhfDisabled || disabled || isSubmitting}
            onClick={async () => {
              await keypadModal.current?.present();
            }}
            ref={inputRef}
            size={size}
          >
            {presentationValue}
          </IonButton>
          <IonButton
            color={incrementIcon.color}
            disabled={rhfDisabled || disabled || isSubmitting}
            fill="clear"
            onClick={incrementHandler}
          >
            <IonIcon icon={incrementIcon.icon} slot="icon-only" />
          </IonButton>
        </Wrapper>
        {!isNil(error) && <IonText color="danger">{error.message}</IonText>}
        <IonModal breakpoints={[0, 1]} initialBreakpoint={1} ref={keypadModal}>
          <div ref={contentRef}>
            <NumberTypePad
              boundaryRange={boundaryRange}
              greenZoneRange={greenZoneRange}
              onChange={onChangeBigNum}
              precision={precision}
              title={title}
              unit={unit}
              value={bnValue ?? bnInitialValue}
            />
          </div>
        </IonModal>
      </>
    );
  }
);
