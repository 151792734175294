import {SignaturePad} from "app/employee/controlCard/input/SignaturePad";
import {FstIonModal} from "app/employee/incdoc/FstIonModal";
import {useTranslation} from "react-i18next";
import {ISyllabusTerm, updateEmployeeSyllabus} from "app/employee/state/syllabus/mutations";
import {EmployeeSignCompletedTermDocument} from "app/gql/graphqlSchema";
import {query} from "app/gql/client";
import {forwardRef} from "react";
import {useLoadingContext} from "app/employee/LoadingContext";
import {ExtractType} from "app/utils/types";

type ISyllabusCompletedTerm = ExtractType<ISyllabusTerm, "CompletedTerm">;

interface IProps {
  term: ISyllabusCompletedTerm;
  onSignatureComplete: () => void;
}

const employeeSignCompletedTerm = async (term: ISyllabusCompletedTerm, signature: string) => {
  const response = await query(EmployeeSignCompletedTermDocument, {input: {termId: term.termId, signature}});
  return response.employeeSignCompletedTerm.syllabus;
};

export const TermSignatureModal = forwardRef<HTMLIonModalElement, IProps>(function TermSignatureModalInner(
  {term, onSignatureComplete},
  ref
) {
  const {t} = useTranslation();
  const {startLoading, finishLoading, showExceptionError} = useLoadingContext();

  const handleSignatureSubmit = async (signature: string) => {
    try {
      startLoading();
      const result = await employeeSignCompletedTerm(term, signature);
      updateEmployeeSyllabus(result);
    } catch (e) {
      await showExceptionError(e);
    } finally {
      finishLoading();
    }
    onSignatureComplete();
  };

  return (
    <FstIonModal ref={ref}>
      <div className="ion-padding">
        {t("syllabus.term-signature.title", {
          defaultValue: "Подпишете се за протокола, че сте преминали това обучение"
        })}
        :
      </div>
      <SignaturePad onSubmit={handleSignatureSubmit} title={t("syllabus.completed.completed-course")} />
    </FstIonModal>
  );
});
