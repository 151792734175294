import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {filterOutline, trashBinOutline} from "ionicons/icons";
import {useRef} from "react";
import {useTranslation} from "react-i18next";
import {DEFAULT_INC_DOC_ORDER, INC_DOC_ORDERS, useIncDocStore} from "app/employee/state/incdoc/mutations";
import {selectMaybeLoaded} from "app/state/common/loadable";
import {shallow} from "zustand/shallow";
import {emptyListToNull, isEqual, isNil} from "app/utils/stdlib";
import {FstIonModal} from "app/employee/incdoc/FstIonModal";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {useEmployeeLocations} from "app/employee/state/bootstrapEmployee/selectors";
import {usePermissions} from "app/employee/hooks/usePermissions";
import {z} from "zod";
import {zLocalDate, zLocationId, zPageOrderInput, zSupplierId} from "app/utils/validator";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {RhfIonInput} from "app/employee/rhf/RhfIonInput";
import {RhfFstIonSelect} from "app/employee/rhf/RhfFstIonSelect";
import {RhfFstIonDatetimeButton} from "app/employee/rhf/RhfFstIonDatetimeButton";
import {DeepPartial, UnreachableCaseError} from "ts-essentials";
import {RelativeDateDisplay} from "app/employee/RelativeDateDisplay";

interface IProps {
  refreshDocuments: () => Promise<void>;
}

const formSchema = z.object({
  query: z.string().trim().nullish(),
  locationIds: z.array(zLocationId).nullish().transform(emptyListToNull),
  supplierIds: z.array(zSupplierId).nullish().transform(emptyListToNull),
  docDateRange: z
    .object({
      start: zLocalDate.nullish(),
      end: zLocalDate.nullish()
    })
    .nullish(),
  createdAtRange: z
    .object({
      start: zLocalDate.nullish(),
      end: zLocalDate.nullish()
    })
    .nullish(),
  order: z.object({order: zPageOrderInput.array()}).nullish()
});

type IFormData = z.infer<typeof formSchema>;

const FilterIcon = ({predicate}: {predicate: DeepPartial<IFormData>}) => {
  let filtersCount = 0;
  if (!isNil(predicate.query) && predicate.query !== "") {
    filtersCount++;
  }
  if (!isNil(predicate.locationIds) && predicate.locationIds.length > 0) {
    filtersCount++;
  }
  if (!isNil(predicate.supplierIds) && predicate.supplierIds.length > 0) {
    filtersCount++;
  }
  if (!isNil(predicate.docDateRange?.start)) {
    filtersCount++;
  }
  if (!isNil(predicate.docDateRange?.end)) {
    filtersCount++;
  }
  if (!isNil(predicate.createdAtRange?.start)) {
    filtersCount++;
  }
  if (!isNil(predicate.createdAtRange?.end)) {
    filtersCount++;
  }
  if (!isEqual(predicate.order, DEFAULT_INC_DOC_ORDER.order)) {
    filtersCount++;
  }
  if (filtersCount > 0) {
    return (
      <>
        {filtersCount} <IonIcon icon={filterOutline} slot="end" />
      </>
    );
  } else {
    return <IonIcon icon={filterOutline} slot="icon-only" />;
  }
};

const IncDocOrderLabel = ({value}: {value: "createdAt" | "documentDate"}) => {
  const {t} = useTranslation();
  switch (value) {
    case "createdAt":
      return <>{t("incdoc.filter.order-by.created-at", {defaultValue: "Дата на създаване"})}</>;
    case "documentDate":
      return <>{t("incdoc.filter.order-by.document-date", {defaultValue: "Дата на документа"})}</>;
    default:
      throw new UnreachableCaseError(value);
  }
};

export const IncDocFilter = ({refreshDocuments}: IProps) => {
  const {t} = useTranslation();
  const modal = useRef<HTMLIonModalElement | null>(null);

  const {canViewIncDocLocation} = usePermissions();
  const locations = useEmployeeLocations().filter((l) => canViewIncDocLocation(l.id));
  const {suppliers, predicate} = useIncDocStore(
    (state) => ({
      suppliers: selectMaybeLoaded(state.suppliers) ?? [],
      predicate: state.predicate
    }),
    shallow
  );

  const showModal = () => modal.current?.present();
  const hideModal = () => modal.current?.dismiss();

  const applyFilter = async (formData: IFormData) => {
    useIncDocStore.setState((state) => {
      state.predicate.query = formData.query;
      state.predicate.locationIds = formData.locationIds;
      state.predicate.supplierIds = formData.supplierIds;
      state.predicate.docDateRange = formData.docDateRange;
      state.predicate.createdAtRange = formData.createdAtRange;
      state.predicate.page.order = formData.order?.order ?? DEFAULT_INC_DOC_ORDER.order.order;
    });
    await hideModal();
    await refreshDocuments();
  };

  const clearFilter = async () => {
    useIncDocStore.setState((state) => {
      state.predicate.query = null;
      state.predicate.locationIds = null;
      state.predicate.supplierIds = null;
      state.predicate.docDateRange = null;
      state.predicate.createdAtRange = null;
      state.predicate.page.order = DEFAULT_INC_DOC_ORDER.order.order;
    });
    await hideModal();
    await refreshDocuments();
  };

  const {control, handleSubmit, watch} = useForm<IFormData>({
    values: {
      order: {order: predicate.page.order},
      docDateRange: predicate.docDateRange,
      createdAtRange: predicate.createdAtRange,
      locationIds: predicate.locationIds,
      supplierIds: predicate.supplierIds,
      query: predicate.query
    },
    resolver: zodResolver(formSchema)
  });

  const docDateAfter = watch("docDateRange.start");
  const docDateBefore = watch("docDateRange.end");
  const createdAtAfter = watch("createdAtRange.start");
  const createdAtBefore = watch("createdAtRange.end");

  const title = t("incdoc.filter.title", {defaultValue: "Филтрирай документите"});
  const submitForm = handleSubmit(applyFilter);
  return (
    <>
      <IonButton fill="clear" onClick={showModal}>
        <FilterIcon predicate={watch()} />
      </IonButton>
      <FstIonModal ref={modal}>
        <IonHeader>
          <IonToolbar>
            <HomeBackButton />
            <IonTitle>{title}</IonTitle>
            <MenuButton />
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList>
            <IonItem>
              <RhfIonInput
                clearInput
                label={t("incdoc.filter.keyword", {defaultValue: "Текст"})}
                labelPlacement="floating"
                onSubmitValue={submitForm}
                rhf={{control, name: "query"}}
              />
            </IonItem>
            <IonItem>
              <RhfFstIonSelect
                label={t("incdoc.doc.location", {defaultValue: "Обект"})}
                labelPlacement="floating"
                multiple
                rhf={{control, name: "locationIds"}}
              >
                {locations.map((location) => (
                  <IonSelectOption key={location.id.locationId} value={location.id}>
                    {location.name} ({location.address})
                  </IonSelectOption>
                ))}
              </RhfFstIonSelect>
            </IonItem>
            <IonItem>
              <RhfFstIonSelect
                label={t("incdoc.doc.supplier", {defaultValue: "Доставчик"})}
                labelPlacement="floating"
                multiple
                rhf={{control, name: "supplierIds"}}
              >
                {suppliers.map((supplier) => (
                  <IonSelectOption key={supplier.id.supplierId} value={supplier.id}>
                    {supplier.name}
                  </IonSelectOption>
                ))}
              </RhfFstIonSelect>
            </IonItem>
            <IonItem>
              <IonLabel>
                {t("incdoc.filter.created-date-after")}
                {!isNil(createdAtAfter) && (
                  <p>
                    <RelativeDateDisplay dt={createdAtAfter} />
                  </p>
                )}
              </IonLabel>
              <RhfFstIonDatetimeButton
                multiple={false}
                presentation="date"
                rhf={{control, name: "createdAtRange.start"}}
                slot="end"
              >
                <IonLabel slot="title">{t("incdoc.filter.created-date-after")}</IonLabel>
              </RhfFstIonDatetimeButton>
            </IonItem>

            <IonItem>
              <IonLabel>
                {t("incdoc.filter.created-date-before")}
                {!isNil(createdAtBefore) && (
                  <p>
                    <RelativeDateDisplay dt={createdAtBefore} />
                  </p>
                )}
              </IonLabel>
              <RhfFstIonDatetimeButton
                multiple={false}
                presentation="date"
                rhf={{control, name: "createdAtRange.end"}}
                slot="end"
              >
                <IonLabel slot="title">{t("incdoc.filter.created-date-before")}</IonLabel>
              </RhfFstIonDatetimeButton>
            </IonItem>
            <IonItem>
              <IonLabel>
                {t("incdoc.filter.doc-date-after")}
                {!isNil(docDateAfter) && (
                  <p>
                    <RelativeDateDisplay dt={docDateAfter} />
                  </p>
                )}
              </IonLabel>
              <RhfFstIonDatetimeButton
                multiple={false}
                presentation="date"
                rhf={{control, name: "docDateRange.start"}}
                slot="end"
              >
                <IonLabel slot="title">{t("incdoc.filter.doc-date-after")}</IonLabel>
              </RhfFstIonDatetimeButton>
            </IonItem>

            <IonItem>
              <IonLabel>
                {t("incdoc.filter.doc-date-before")}
                {!isNil(docDateBefore) && (
                  <p>
                    <RelativeDateDisplay dt={docDateBefore} />
                  </p>
                )}
              </IonLabel>
              <RhfFstIonDatetimeButton
                multiple={false}
                presentation="date"
                rhf={{control, name: "docDateRange.end"}}
                slot="end"
              >
                <IonLabel slot="title">{t("incdoc.filter.doc-date-before")}</IonLabel>
              </RhfFstIonDatetimeButton>
            </IonItem>
            <IonItem>
              <RhfFstIonSelect
                label={t("incdoc.filter.order")}
                labelPlacement="floating"
                rhf={{control, name: "order"}}
              >
                {INC_DOC_ORDERS.map((o) => {
                  return (
                    <IonSelectOption key={o.label} value={o.order}>
                      <IncDocOrderLabel value={o.label} />
                    </IonSelectOption>
                  );
                })}
              </RhfFstIonSelect>
            </IonItem>
          </IonList>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="primary">
              <IonButton color="primary" fill="outline" onClick={clearFilter}>
                <IonIcon icon={trashBinOutline} slot="start" />
                {t("g.clear", {defaultValue: "Изчисти"})}
              </IonButton>
              <IonButton color="primary" fill="solid" onClick={submitForm}>
                <IonIcon icon={filterOutline} slot="start" />
                {t("g.filter", {defaultValue: "Приложи филтъра"})}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </FstIonModal>
    </>
  );
};
