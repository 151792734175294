import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter
} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {Trans, useTranslation} from "react-i18next";
import {useQuery} from "app/employee/controlCard/hook/useQuery";
import {
  GetEmployeeManagerBroadcastDocument,
  IGetEmployeeManagerBroadcastQuery,
  IManagerBroadcastMarkAsReadInput,
  MarkAsReadManagerBroadcastDocument
} from "app/gql/graphqlSchema";
import {LoadingLoadable} from "app/employee/Loading";
import {isNil} from "app/utils/stdlib";
import {
  useSafePathParams,
  useSafeQueryParams,
  zBroadcastDeliveryId,
  zBroadcastIdParams
} from "app/components/hooks/useSafeParams";
import {useEmployeeClientId} from "app/employee/state/bootstrapEmployee/selectors";
import {RichTextView} from "app/components/admin/rte/RichTextView";
import {checkmarkCircleOutline, closeOutline} from "ionicons/icons";
import {useBackNavigation} from "app/employee/hooks/useBackNavigation";
import {linkEmplNotifications} from "app/employee/core/employeeLinks";
import {query} from "app/gql/client";
import {useLoadingContext} from "app/employee/LoadingContext";
import {Timestamp} from "app/employee/Timestamp";
import {markAsReadInAppNotification} from "app/employee/notification/utils";
import {useRef} from "react";
import {SignaturePad} from "app/employee/controlCard/input/SignaturePad";
import {FstIonModal} from "app/employee/incdoc/FstIonModal";
import {styled} from "styled-components";

const stateMapper = (n: IGetEmployeeManagerBroadcastQuery) => n.getEmployeeManagerBroadcast;
const useBroadcast = () => useQuery(GetEmployeeManagerBroadcastDocument, stateMapper);

const markAsRead = (input: IManagerBroadcastMarkAsReadInput) => query(MarkAsReadManagerBroadcastDocument, {input});

const BroadcastFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px 16px;
`;

const SentBy = styled.em``;

const ReadBy = styled.em``;

const Signature = styled.div`
  padding: 0.5rem;
  display: flex;
  justify-content: center;
`;

const SignatureImage = styled.img`
  max-height: 6rem;
  max-width: 6rem;
`;

export const Broadcast = () => {
  const goBack = useBackNavigation(linkEmplNotifications());
  const {startLoading, finishLoading, showExceptionError} = useLoadingContext();

  const {t} = useTranslation();
  const {broadcastId} = useSafePathParams(zBroadcastIdParams);
  const {deliveryId} = useSafeQueryParams(zBroadcastDeliveryId);
  const clientId = useEmployeeClientId();
  const {data: broadcast, fetch, refetch} = useBroadcast();
  const signatureModalRef = useRef<HTMLIonModalElement | null>(null);

  useIonViewWillEnter(() => {
    fetch({
      input: {
        broadcastId,
        clientId
      }
    });
  }, [broadcastId, clientId, fetch]);

  const handleMarkAsRead = async (signatureBase64: string) => {
    try {
      startLoading();
      const promises: Promise<unknown>[] = [
        markAsRead({
          managerBroadcastId: {broadcastId, clientId},
          signature: signatureBase64
        })
      ];
      if (!isNil(deliveryId)) {
        promises.push(markAsReadInAppNotification(deliveryId));
      }
      await Promise.all(promises);
      goBack();
    } catch (e) {
      await showExceptionError(e);
    } finally {
      finishLoading();
    }
  };

  const handleMarkAsReadButton = async () => {
    await signatureModalRef.current?.present();
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{broadcast?.value?.broadcast.subject}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <LoadingLoadable loadable={broadcast} />
        <IonRefresher onIonRefresh={refetch} slot="fixed">
          <IonRefresherContent />
        </IonRefresher>
        {!isNil(broadcast?.value) && (
          <>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{broadcast.value.broadcast.subject}</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <RichTextView html={broadcast.value.broadcast.htmlContent} />
              </IonCardContent>
              <BroadcastFooter>
                <SentBy>
                  <Trans
                    components={{
                      Name: <strong>{broadcast.value.broadcast.creator.name}</strong>,
                      SentAt: <Timestamp ts={broadcast.value.broadcast.sentAt} />
                    }}
                    i18nKey="broadcast.from"
                    t={t}
                  />
                </SentBy>
              </BroadcastFooter>
            </IonCard>
            {!isNil(broadcast.value.employeeRecipient.readAt) && (
              <IonCard>
                <IonCardContent>
                  <ReadBy>
                    {!isNil(broadcast.value.employeeRecipient.signature) && (
                      <Signature>
                        <SignatureImage src={broadcast.value.employeeRecipient.signature} />
                      </Signature>
                    )}
                    <div>
                      <Trans
                        components={{
                          Name: <strong>{broadcast.value.employeeRecipient.employee.name}</strong>,
                          ReadAt: <Timestamp ts={broadcast.value.employeeRecipient.readAt} />
                        }}
                        i18nKey="broadcast.read-at"
                        t={t}
                      />
                    </div>
                  </ReadBy>
                </IonCardContent>
              </IonCard>
            )}
          </>
        )}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="primary">
            <IonButton color="primary" fill="outline" onClick={goBack}>
              <IonIcon icon={closeOutline} slot="start" />
              {t("g.close")}
            </IonButton>
            {isNil(broadcast.value?.employeeRecipient.readAt) && (
              <IonButton color="primary" fill="solid" onClick={handleMarkAsReadButton}>
                <IonIcon icon={checkmarkCircleOutline} slot="start" />
                {t("broadcast.mark-as-read")}
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonFooter>
      <FstIonModal ref={signatureModalRef}>
        <div className="ion-padding">{t("broadcast.mark-as-read-confirm.title")}:</div>
        <SignaturePad onSubmit={handleMarkAsRead} title={broadcast?.value?.broadcast.subject} />
      </FstIonModal>
    </>
  );
};
