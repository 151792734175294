import {hofForwardRef, IIonButton, MaybeNil, noop, RhfProps, RhfRef} from "app/utils/types";
import {FieldPath, FieldValues, useController} from "react-hook-form";
import {ForwardedRef, RefCallback, useImperativeHandle, useMemo, useRef} from "react";
import {IControlCardTemperatureRange, IInitialValueFragment} from "app/gql/graphqlSchema";
import {IonButton, IonIcon, useIonPicker} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {PickerColumnOption} from "@ionic/core/dist/types/components/picker/picker-interface";
import {z} from "zod";
import {assignRef, isNil} from "app/utils/stdlib";
import {Color} from "@ionic/core";
import {UnreachableCaseError} from "ts-essentials";
import {styled} from "styled-components";
import {snow, sunny} from "ionicons/icons";
import BigNumber from "bignumber.js";
import {toBigNumber, zBigNumber} from "app/utils/validator";

export interface ITemperatureInputProps extends Pick<IIonButton, "disabled" | "size"> {
  range: IControlCardTemperatureRange;
  initialValue: MaybeNil<IInitialValueFragment>;
}

interface ITemperatureColumnOption extends PickerColumnOption {
  value: BigNumber;
}

const reportValidity = () => true;

const pickerValueSchema = z.object({
  temp: z.object({
    value: zBigNumber
  })
});

const PRECISION = 1;
const ROUNDING_MODE = BigNumber.ROUND_HALF_EVEN;

const Wrapper = styled.div`
  display: flex;
`;

const toCanonicalNumber = (value: string | number | BigNumber): BigNumber => {
  return toBigNumber(value).decimalPlaces(PRECISION, ROUNDING_MODE);
};

const getDefaultSelectedValue = (range: IControlCardTemperatureRange) => {
  switch (range.__typename) {
    case "ControlCardClosedTemperatureRange": {
      const start = toCanonicalNumber(range.start);
      const end = toCanonicalNumber(range.end);
      const middle = end.minus(start).div(2).plus(start);
      return toCanonicalNumber(middle);
    }
    case "ControlCardOpenStartTemperatureRange": {
      return toCanonicalNumber(range.end);
    }
    case "ControlCardOpenEndTemperatureRange": {
      return toCanonicalNumber(range.start);
    }
    case undefined:
      throw new Error("Range is undefined");
    default:
      throw new UnreachableCaseError(range);
  }
};

const getValueSelectedIndex = (options: ITemperatureColumnOption[], value: BigNumber) => {
  const selectedIndex = options.findIndex((option) => option.value.isEqualTo(value));
  if (selectedIndex >= 0) {
    return selectedIndex;
  }
  options.push(createOption(value));
  options.sort((a, b) => a.value.comparedTo(b.value));
  const index = options.findIndex((option) => option.value.isEqualTo(value));
  return index >= 0 ? index : 0;
};

const getInitialValue = (initialValue: MaybeNil<IInitialValueFragment>) => {
  if (isNil(initialValue)) {
    return null;
  }
  switch (initialValue.__typename) {
    case "ControlCardInitialFixedValue":
      if (initialValue.value.__typename === "ControlCardTemperatureValue") {
        return toCanonicalNumber(initialValue.value.temperature);
      } else {
        console.warn("Initial value is not a temperature value", initialValue.value);
        return null;
      }
    default:
      throw new UnreachableCaseError(initialValue.__typename);
  }
};

const findSelectedIndex = (
  range: IControlCardTemperatureRange,
  initialValue: MaybeNil<IInitialValueFragment>,
  options: ITemperatureColumnOption[],
  value: BigNumber | null
) => {
  if (!isNil(value)) {
    return getValueSelectedIndex(options, value);
  } else {
    let defaultSelectedValue = getInitialValue(initialValue);
    if (isNil(defaultSelectedValue)) {
      defaultSelectedValue = getDefaultSelectedValue(range);
    }
    return getValueSelectedIndex(options, defaultSelectedValue);
  }
};

const isOutsideRange = (value: BigNumber | null, range: IControlCardTemperatureRange) => {
  if (isNil(value)) {
    return false;
  }
  switch (range.__typename) {
    case "ControlCardClosedTemperatureRange":
      return value.isLessThan(toCanonicalNumber(range.start)) || value.isGreaterThan(toCanonicalNumber(range.end));
    case "ControlCardOpenStartTemperatureRange":
      return value.isGreaterThan(toCanonicalNumber(range.end));
    case "ControlCardOpenEndTemperatureRange":
      return value.isLessThan(toCanonicalNumber(range.start));
    case undefined:
      throw new Error("Range is undefined");
    default:
      throw new UnreachableCaseError(range);
  }
};

const getSelectionRange = (range: IControlCardTemperatureRange): [BigNumber, BigNumber, BigNumber] => {
  const increment = toBigNumber(range.step);
  switch (range.__typename) {
    case "ControlCardClosedTemperatureRange":
      return [toCanonicalNumber(range.start).minus(5), toCanonicalNumber(range.end).plus(5), increment];
    case "ControlCardOpenStartTemperatureRange": {
      const end = toCanonicalNumber(range.end);
      return [end.minus(20), end.plus(5), increment];
    }
    case "ControlCardOpenEndTemperatureRange": {
      const start = toCanonicalNumber(range.start);
      return [start.minus(5), start.plus(20), increment];
    }
    case undefined:
      throw new Error("Range is undefined");
    default:
      throw new UnreachableCaseError(range);
  }
};

const getDeltaValue = (currentValue: BigNumber | null, defaultValue: BigNumber, delta: BigNumber) => {
  let deltaValue: BigNumber;
  if (isNil(currentValue)) {
    deltaValue = defaultValue;
  } else {
    deltaValue = currentValue.plus(delta);
  }
  return deltaValue.toFixed(PRECISION, ROUNDING_MODE);
};

const createOption = (value: BigNumber) => {
  const tempStr = value.toFixed(PRECISION, ROUNDING_MODE);
  return {
    text: `${tempStr} °C`,
    value
  };
};

export const RhfTemperatureInput = hofForwardRef(
  <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    {rhf, size, range, initialValue, disabled}: ITemperatureInputProps & RhfProps<TFieldValues, TName>,
    ref: ForwardedRef<HTMLIonButtonElement>
  ) => {
    const {t} = useTranslation();
    const {
      field: {onChange, ref: fieldRef, value, disabled: rhfDisabled, ...restField},
      formState: {isSubmitting}
    } = useController(rhf);

    const rhfRef = useRef<RhfRef | null>(null);
    const inputRef: RefCallback<HTMLIonButtonElement> = (element) => {
      assignRef(ref, element);

      rhfRef.current = {
        focus: () => element?.focus(),
        select: () => element?.focus(),
        setCustomValidity: noop,
        reportValidity
      };
    };
    useImperativeHandle(fieldRef, () => rhfRef.current);

    const [present] = useIonPicker();

    const {options, parsedValue, selectedIndex, increment} = useMemo(() => {
      const [start, end, increment] = getSelectionRange(range);

      const options: ITemperatureColumnOption[] = [];
      for (let i = start; i.isLessThanOrEqualTo(end); i = i.plus(increment)) {
        const value = toCanonicalNumber(i);
        options.push(createOption(value));
      }

      const parsedValue = !isNil(value) ? toCanonicalNumber(value) : null;
      const selectedIndex = findSelectedIndex(range, initialValue, options, parsedValue);
      return {options, parsedValue, selectedIndex, increment};
    }, [initialValue, range, value]);

    const openPicker = async () => {
      await present({
        columns: [
          {
            name: "temp",
            options,
            selectedIndex
          }
        ],
        buttons: [
          {
            text: t("g.cancel"),
            role: "cancel"
          },
          {
            text: t("g.clear"),
            handler: () => {
              onChange(null);
            }
          },
          {
            text: t("g.ok"),
            handler: (value) => {
              const parseResult = pickerValueSchema.parse(value);
              onChange(parseResult.temp.value.toFixed(PRECISION, ROUNDING_MODE));
            }
          }
        ]
      });
    };

    const presentationValue = isNil(parsedValue) ? (
      t("g.select-temperature")
    ) : (
      <>{parsedValue.toFixed(PRECISION, ROUNDING_MODE)} °C</>
    );

    const color: Color = isNil(parsedValue) ? "warning" : isOutsideRange(parsedValue, range) ? "danger" : "success";
    const decrementHandler = () =>
      onChange(getDeltaValue(parsedValue, options[selectedIndex].value, increment.negated()));
    const incrementHandler = () => onChange(getDeltaValue(parsedValue, options[selectedIndex].value, increment));

    return (
      <Wrapper>
        <IonButton
          color="cold"
          disabled={isNil(selectedIndex) || rhfDisabled || disabled || isSubmitting}
          fill="clear"
          onClick={decrementHandler}
        >
          <IonIcon icon={snow} slot="icon-only" />
        </IonButton>
        <IonButton
          {...restField}
          color={color}
          disabled={rhfDisabled || disabled || isSubmitting}
          onClick={openPicker}
          ref={inputRef}
          size={size}
        >
          {presentationValue}
        </IonButton>
        <IonButton
          color="hot"
          disabled={isNil(selectedIndex) || rhfDisabled || disabled || isSubmitting}
          fill="clear"
          onClick={incrementHandler}
        >
          <IonIcon icon={sunny} slot="icon-only" />
        </IonButton>
      </Wrapper>
    );
  }
);
