import {AlertButton, IonAlert, useIonRouter, useIonViewDidEnter, useIonViewWillLeave} from "@ionic/react";
import {useNotificationCount} from "app/employee/notification/useNotificationCount";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {linkEmplNotifications} from "app/employee/core/employeeLinks";
import {useBoolean} from "@fluentui/react-hooks";
import {useNotificationLastRefresh} from "app/employee/notification/useNotificationLastRefresh";
import {DateTime} from "luxon";
import {useNotificationsAlertLastDismiss} from "app/employee/notification/useNotificationsAlertLastDismiss";

const COOL_OFF_PERIOD_SECONDS = 60 * 5;
const getNowInSeconds = () => DateTime.now().toSeconds();

export const NotificationAlert = () => {
  const {t} = useTranslation();
  const {push} = useIonRouter();
  const {unreadCount} = useNotificationCount();
  const refreshCount = useNotificationLastRefresh();
  const [alertEnabled, setAlertEnabled] = useState(true);
  const [isOpen, {setTrue: openAlert, setFalse: closeAlert}] = useBoolean(false);
  const {lastDismissedAtSeconds, setLastDismissedAtSeconds} = useNotificationsAlertLastDismiss();

  const buttons = useMemo<AlertButton[]>(
    () => [
      {
        text: t("g.later", {defaultValue: "По-късно"}),
        role: "cancel"
      },
      {
        text: t("g.yes", {defaultValue: "Да"}),
        handler: () => {
          push(linkEmplNotifications(), "forward");
        }
      }
    ],
    [push, t]
  );

  const handleDismiss = useCallback(() => {
    setLastDismissedAtSeconds(getNowInSeconds());
    closeAlert();
  }, [closeAlert, setLastDismissedAtSeconds]);

  useEffect(() => {
    const nowSeconds = getNowInSeconds();
    const secondsSinceLastDismiss = nowSeconds - lastDismissedAtSeconds;
    if (alertEnabled && unreadCount > 0 && secondsSinceLastDismiss > COOL_OFF_PERIOD_SECONDS) {
      openAlert();
    } else {
      closeAlert();
    }
  }, [closeAlert, openAlert, unreadCount, refreshCount, alertEnabled, lastDismissedAtSeconds]);

  useIonViewDidEnter(() => {
    setAlertEnabled(true);
  }, []);

  useIonViewWillLeave(() => {
    setAlertEnabled(false);
  }, []);

  return (
    <IonAlert
      buttons={buttons}
      header={t("notifications.unread-alert.header", {
        count: unreadCount
      })}
      isOpen={isOpen}
      message={t("notifications.unread-alert.message", {
        count: unreadCount
      })}
      onDidDismiss={handleDismiss}
    />
  );
};
