import {useEmployeeNotificationState} from "app/employee/state/notification/mutations";
import {useMemo} from "react";
import {isNil} from "app/utils/stdlib";

export const useNotificationCount = () => {
  const notifications = useEmployeeNotificationState((state) => state.notifications);

  return useMemo(() => {
    const totalCount = notifications.value?.length ?? 0;
    const unreadCount = notifications.value?.filter((n) => isNil(n.readAt)).length ?? 0;
    return {totalCount, unreadCount};
  }, [notifications.value]);
};
