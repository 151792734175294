import {ISyllabusTerm} from "app/employee/state/syllabus/mutations";
import {DetailedProgress} from "app/employee/syllabus/DetailedProgress";
import {useTranslation} from "react-i18next";

interface IProps {
  terms: ISyllabusTerm[];
}

export const SyllabusPendingTermPlan = ({terms}: IProps) => {
  const pendingPlans = terms
    .flatMap((t) => t.termPlans.map((tp) => ({term: t, termPlan: tp})))
    .filter((tp) => !tp.termPlan.completed);

  const {t} = useTranslation();
  if (pendingPlans.length > 0) {
    return <DetailedProgress plansWithTerm={pendingPlans} />;
  } else {
    return <h4>{t("syllabus.pending.no-pending-course", {defaultValue: "Няма текущо обучение"})}</h4>;
  }
};
